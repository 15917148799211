import { Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, useToast, VStack } from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

const Signup = () => {
    const [show, setShow]= useState(false);
    const [name, setName]=useState();
    const [email, setEmail]=useState();
    const [password, setPassword]=useState();
    const [confirmPassword, setConfirmPassword]=useState();
    const [pic, setPic]=useState();
    const [loading, setLoading]= useState(false);
    const toast = useToast()
    const history= useHistory();
    const handleClick=()=>{
        setShow(!show);
    };
    const postDetails=(pic)=>{
      setLoading(true);
      if(pic===undefined){
        toast({
          title: 'Please select an Image!',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position:"bottom",
        });
        return;
      }
      if(pic.type==="image/jpeg" || pic.type==="image/png"){
        const data= new FormData();
        data.append("file", pic);
        data.append("upload_preset","samanvay");
        data.append("cloud_name","sravya");
        fetch("https://api.cloudinary.com/v1_1/sravya/image/upload",{
          method:"post",
          body:data,
        }).then((res)=>res.json())
        .then(data=>{
          setPic(data.url.toString());
          console.log(data.url.toString());
          
          setLoading(false);          
        })
        .catch((err)=>{
          console.log(err);
          setLoading(false);
          
        });
      }
      else{
        toast({
          title: 'Please select an Image!',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position:"bottom",
        });
        setLoading(false);
        return;
      }
    };
    const submitHandler= async(e)=>{
        e.preventDefault();
        setLoading(true);
        if(!name|| !email || !password || !confirmPassword){
          toast({
          title: 'Please fill all the Fields',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position:"bottom",
          });
          setLoading(false);
          return;
        }
        if(password!==confirmPassword){
          toast({
          title: 'Passwords donot match',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position:"bottom",
          });
          return;
        }
        try{
          const config={
            headers:{
              "Content-type":"application/json",
            },
          };
          const {data}= await axios.post("/api/user",{name, email, password, pic}, config);
          toast({
          title: 'Registration Successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position:"bottom",
          });
          localStorage.setItem("userInfo", JSON.stringify(data));
          setLoading(false);
          history.push("/chats")
        }
        catch(error){
          toast({
          title: 'Error Occured!',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position:"bottom",
          });
          setLoading(false);
        }
    }
  return (
    <VStack spacing={"5px"}>
      <FormControl id="first-name" isRequired>
        <FormLabel>
            Name
        </FormLabel>
        <Input placeholder='Enter your Name'
        onChange={(e)=>setName(e.target.value)}/>
      </FormControl>
      <FormControl id="email" isRequired>
        <FormLabel>
            Email
        </FormLabel>
        <Input type="email" placeholder='Enter your Email'
        onChange={(e)=>setEmail(e.target.value)}/>
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel>
            Password
        </FormLabel>
        <InputGroup size="md">
            <Input type={show?"text":"password"} placeholder='Enter your password' onChange={(e)=>setPassword(e.target.value)}/>
            <InputRightElement width={"4.5rem"}>
                <Button h="1.75rem" size={"sm"} onClick={handleClick}>
                    {show?"Hide":"Show"}
                </Button>
            </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel>
            Password
        </FormLabel>
        <InputGroup size={"md"}>
            <Input type={show?"text":"password"} placeholder='Confirm password' onChange={(e)=>setConfirmPassword(e.target.value)}/>
            <InputRightElement width={"4.5rem"}>
                <Button h="1.75rem" size={"sm"} onClick={handleClick}>
                    {show?"Hide":"Show"}
                </Button>
            </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="pic">
        <FormLabel>
            Upload your picture
        </FormLabel>
        <Input type="file" p={1.5} accept='image/*'
        onChange={(e)=>postDetails(e.target.files[0])}/>
      </FormControl>
      <Button colorScheme='blue' width={"100%"} style={{marginTop: 15}} onClick={submitHandler} isLoading={loading}>
        Sign Up
      </Button>
    </VStack>
  )
}

export default Signup
