import React from 'react'
import { useChatState } from './ChatProvider'
import { Box } from '@chakra-ui/react';
import SingleChat from '../components/SingleChat';

const ChatBox = ({fetchAgain, setFetchAgain}) => {
  const {selectedChat}= useChatState();
  return (
    <Box display={{base:selectedChat?"flex":"none",md:"flex"}} alignItems={"center"}
    flexDir={"column"} p={3} bg={"white"} w={{base:"100%", md:"68%"}}
    borderRadius={"lg"} borderWidth={"1px"}>
      <SingleChat fetchAgain={fetchAgain} setFetchAgain={setFetchAgain}/>
    </Box>
  )
}

export default ChatBox
